import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Home extends React.Component {

  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const latestPost = data.allMarkdownRemark.edges[0].node;

    return (
      <Layout location={location} title={siteTitle}>

        <SEO title="Writing, Side-projects, Newsletter and More" />

        <div className="home-image">
          <Image
            fluid={data.profile.childImageSharp.fluid}
            alt="stevan with lots of ducks in Vietnam"
          />
        </div>

        <Bio />

        <p className="latest">
          <span role="img" aria-label="new emoji">🆕</span> Latest Post: <Link to={latestPost.fields.slug}>{latestPost.frontmatter.title}</Link>
        </p>
    
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profile: file(absolutePath: { regex: "/duck-stop.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 760, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {
          type: { ne: "page" }
        }
      }
      limit: 1
      ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMM, YYYY")
            title
          }
        }
      }
    }
  }
`
