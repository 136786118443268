/**
 * Bio component
 */

import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

function Bio() {
  return (
    <div className="bio">
      <p className="tagline">
        A commercial operator focused on go-to-market at venture-backed
        startups.
      </p>
      <p>
        This is a space to explore my curiosities. It’s a means to clarify,
        share and sharpen my thinking in public. I seek broad knowledge, avoid
        hyper specialization and prefer practice to theory. My interests include
        entrepreneurship, learning, software and investing. My work explores
        intentional behaviors to improve ourselves, our careers and our
        businesses.
      </p>
      <p>
        <span role="img" aria-label="man with laptop emoji">
          👨🏽‍💻
        </span>{" "}
        First sales hire at{" "}
        <OutboundLink href="https://blueprint.store/">Blueprint</OutboundLink>{" "}
        (acquired). Also scaled sales, marketing and partnerships with{" "}
        <OutboundLink href="https://deliveroo.co.uk/">Deliveroo</OutboundLink>{" "}
        (IPO) and YPlan (acquired). Previously, developed software and product
        with <OutboundLink href="https://onin.co/">Onin</OutboundLink> and
        Digital Fineprint (acquired).
      </p>
      <p>
        <span role="img" aria-label="hammer and spanner emoji">
          🛠️
        </span>{" "}
        Designed, built and launched{" "}
        <OutboundLink href="https://www.stockideas.club/">
          Stock Ideas
        </OutboundLink>
        ,{" "}
        <OutboundLink href="https://www.showmethemoney.club/">
          Show Me The Money
        </OutboundLink>
        ,{" "}
        <OutboundLink href="https://www.codewithbootcamps.com/">
          Code With Bootcamps
        </OutboundLink>{" "}
        and <OutboundLink href="http://dreamweeks.co/">Dreamweeks</OutboundLink>
        .
      </p>
      <p>
        <span role="img" aria-label="pen emoji">
          🖋️
        </span>{" "}
        Writing has been featured in Huffington Post and tech publications.
        Taught coding to students at UCL, LSE & Nottingham Trent.
      </p>
    </div>
  )
}

export default Bio
